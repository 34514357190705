<script >
import { reactive, onMounted, ref, inject, watch } from 'vue'
export default {
  props: {
    total: {
      type: Number
    }
  },
  setup (props) {
    onMounted(() => {
      state.total = props.total
    })

    watch(() => props.total, (newValue, oldValue) => {
      state.total = newValue
    });

    const state = reactive({
      total: 0
    })

    return {
      state
    }
  }
}
</script>

<template>
  <div class="card border h-100">
    <div class="d-flex h-100">
      <div class="align-content-around text-center text-white bg-dark" style="border-radius: 10px; width: 30%">
        <i class="material-icons text-white text-center">star</i>
      </div>
      <div class="align-content-around px-2">
        <div class="font-weight-bold">{{ state.total }}</div>
        <div>TOTAL GERAL DE PLANOS DE TREINOS</div>
      </div>
    </div>

  </div>
</template>

<style scoped>

</style>