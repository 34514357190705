import { defineStore } from 'pinia'

export const useStore = defineStore('coreStore', {
    // arrow function recommended for full type inference
    state: () => {
        return {
            user: null, // Estado do usuário (inicialmente não logado)
            isAuthenticated: false, // Estado de autenticação
            token: null,
            links: [
                {
                    link: '/dashboard',
                    icone: 'dashboard',
                    nome: 'Dashboard'
                },
                {
                    link: '/treinadores',
                    icone: 'dashboard',
                    nome: 'Treinadores'
                },
                {
                    link: '/mensagens',
                    icone: 'dashboard',
                    nome: 'Mensagens'
                }
            ]
        }
    },
    actions: {
        login(token) {
            this.token = token
            this.isAuthenticated = true
        },
        logout() {
            // Faz logout limpando o estado
            this.token = null;
            this.isAuthenticated = false;
        }
    },
    persist: {
        enabled: true, // Ativa a persistência
        strategies: [
            {
                key: 'gestor-store', // O nome da chave no localStorage
                storage: localStorage, // Pode usar sessionStorage se preferir
            },
        ],
    }
})