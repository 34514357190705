<script >
import { reactive, onMounted, ref, inject, watch } from 'vue'
export default {
  props: {
    indicador: {
      type: Object
    }
  },
  setup (props) {
    onMounted(() => {
      const indicador = props.indicador
      console.log(indicador)
      state.total = indicador['total']
      state.masculino = indicador['M']
      state.feminino = indicador['F']
    })

    watch(() => props.indicador, (newValue, oldValue) => {
      console.log('watch')
      state.total = newValue['total']
      state.masculino = newValue['M']
      state.feminino = newValue['F']
    });

    const state = reactive({
      total: 0,
      masculino: 0,
      feminino: 0
    })

    return {
      state
    }
  }
}
</script>

<template>
  <div class="card border p-3">
    <div class="border-bottom pb-2 d-flex">
      <div class="align-content-center mx-3">
        <i class="material-icons">person</i>
      </div>
      <div class="w-100 text-center">
        <div>{{state.total}}</div>
        <div>Total de treinadores</div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-6 border-end">
        <div class=" d-flex">
          <div class="align-content-center mx-3">
            <i class="material-icons">male</i>
          </div>
          <div class="w-100 text-center">
            <div>{{state.masculino}}</div>
            <div>Masculino</div>
          </div>
        </div>
      </div>
      <div class="col-6 border-start">
        <div class="d-flex">
          <div class="align-content-center mx-3">
            <i class="material-icons">female</i>
          </div>
          <div class="w-100 text-center">
            <div>{{state.feminino}}</div>
            <div>Feminino</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>