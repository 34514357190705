import moment from 'moment'
export default {
    datePtbr (value) {
        if (value === null || value === '') return ''
        return value.slice(0, 10).split('-').reverse().join('/')
    },
    currencyBr (value, cifrao = true) {
        if(cifrao) {
            return value.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        }
        return value.toLocaleString('pt-br', {minimumFractionDigits: 2});
    },
    justNumbers(value) {
        return value.replace(/\D/g, "");
    },
    fullAddressFormat(obj = null) {
        if (obj == null) return ''
        return `<span class="font-weight-bold">CEP: </span>${obj.cep}<br>`
            +`<span class="font-weight-bold">Endereço: </span>${obj.street}, ${obj.number} - ${obj.neighborhood}, ${obj.city}/${obj.state}<br>`
    },
    diffTimeMinutesSeconds(time1, time2) {
        if(time1 == 0 || time2 == 0) return 0
        // Definir os horários de início e fim (apenas minutos e segundos)
        var inicio = moment(time1, "mm:ss");
        var fim = moment(time2, "mm:ss");

        // Calcular a diferença em segundos
        var diferencaEmSegundos = fim.diff(inicio, 'seconds') + 60

        // Calcular os minutos e segundos separadamente
        var minutos = Math.floor(diferencaEmSegundos / 60)
        var segundos = diferencaEmSegundos % 60
    },
    converteCentavosValor(valor) {
        if(valor == null || parseInt(valor) === 0) return ''
        const novoValor = (parseInt(valor) / 100).toFixed(2)
        return `R$ ${novoValor}`
    }
}