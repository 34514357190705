<script>
import { reactive, onMounted, ref, inject, watch } from 'vue'
import {useRoute, useRouter} from "vue-router";
import mensagensRepository from "@/services/api/mensagensRepository";
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
  components: {
    QuillEditor
  },
  setup () {

    const router = useRouter()
    const route = useRoute()

    onMounted(() => {
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.mensagemId = parseInt(route.params.id)
        console.log(state.mensagemId)

        buscarDados()
      }
    })

    const state = reactive({
      mensagemId: 0,
      mensagem: {}
    })

    function buscarDados() {
      mensagensRepository.MensagensGetId(state.mensagemId).then(response => {
        if(response.data.success) {
          state.mensagem = response.data.data
        }
      }).catch(error => {
        console.error(error)
      })
    }
    function salvar() {
      if(state.mensagemId > 0) {
        mensagensRepository.MensagensPut(state.mensagemId, state.mensagem).then(response => {
          console.log(response.data)
          if(response.data.success) {
            router.push('/mensagens');
          }

        }).catch(error => {
          console.error(error)
        })
      } else {
        mensagensRepository.MensagensPost(state.mensagem).then(response => {
          console.log(response.data)
          if(response.data.success) {
            router.push('/mensagens');
          }

        }).catch(error => {
          console.error(error)
        })
      }
    }

    function voltar() {
      router.back()
    }

    return {
      state,
      salvar,
      voltar
    }
  }
}
</script>

<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h5>Formulário de mensagem</h5>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label for="">Tipo de mensagem</label>
            <select class="form-control" v-model="state.mensagem.message_type">
              <option value="aniversario">Aniversário</option>
              <option value="anonovo">Ano Novo</option>
              <option value="natal">Natal</option>

            </select>
          </div>
        </div>
        <div v-if="state.mensagem.message_type !== 'aniversario'" class="col-12 col-md-4">
          <div class="form-group">
            <label for="">Dia de envio</label>
            <select class="form-control" v-model="state.mensagem.message_day">
              <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
            </select>
          </div>
        </div>
        <div v-if="state.mensagem.message_type !== 'aniversario'" class="col-12 col-md-4">
          <div class="form-group">
            <label for="">Mês de envio</label>
            <select class="form-control" v-model="state.mensagem.message_month">
              <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label for="">Titulo da mensagem</label>
            <input type="text" name="" id="" class="form-control" v-model="state.mensagem.message_title">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label for="">Mensagem</label>
            <QuillEditor content-type="html" v-model:content="state.mensagem.message_text" theme="snow" style="min-height: 200px;" />
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 text-end">
          <button type="button" class="btn btn-sm btn-warning me-2" @click.prevent="voltar">Voltar</button>
          <button type="button" class="btn btn-sm btn-success" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>