import Repository from './repository'

export default {
    MensagensGet () {
        return Repository.get('messages')
    },
    MensagensGetId (id) {
        return Repository.get(`messages/${id}`)
    },
    MensagensPut (id, item) {
        return Repository.put(`messages/${id}`, item)
    },
    MensagensPost (item) {
        return Repository.post(`messages`, item)
    },
    MensagensDelete (item) {
        return Repository.delete(`messages/${item}`)
    }
}

