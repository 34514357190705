import { createApp } from 'vue'
import App from './App.vue'
const appInstance = createApp(App);

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import router from "./router";
import filters from './filters.js'

import "bootstrap"
//import "./assets/js/material-dashboard.min"
import "./assets/js/menu"
import "./assets/js/plugins/smooth-scrollbar.min"
import "./assets/js/plugins/perfect-scrollbar.min"
//import "./assets/css/material-dashboard.min.css"
import VueSweetalert2 from 'vue-sweetalert2';

import "bootstrap/dist/css/bootstrap.min.css"
import 'sweetalert2/dist/sweetalert2.min.css';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

appInstance.use(VueSweetalert2);
appInstance.use(pinia) // Create Pinia
appInstance.use(router);
appInstance.config.globalProperties.$filters = filters
appInstance.mount('#app')
