<script>
import MyTable from '../../components/MyTable.vue';
import { reactive, onMounted, ref, inject, watch } from 'vue'
import coachesRepository from "@/services/api/coachesRepository";
import AlunosModal from "@/views/treinadores/AlunosModal.vue";
export default {
  components: {
    MyTable,
    AlunosModal
  },
  setup () {

    onMounted(() => {
      buscaTreinadores()
    })

    const state = reactive({
      treinadores: [],
      treinadorId: 0
    })

    const columns = ref([
      {
        label: '',
        field: 'name'
      },
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    function buscaTreinadores () {
      coachesRepository.TreinadoresGet().then(response => {
        if(response.data.success) {
          state.treinadores = response.data.data
        }
      }).catch(error => {
        console.error(error)
      })
    }

    let showAlunos = ref(false)

    function listarAlunos(treinadorId) {
      state.treinadorId = treinadorId
      showAlunos.value = true
    }

    return {
      state,
      columns,
      showAlunos,
      listarAlunos
    }
  }
}
</script>

<template>
  <div>
    <h3 class="mb-3">
      Lista de treinadores
    </h3>
    <alunos-modal
        :treinadorId="state.treinadorId"
        :show="showAlunos"
        v-show="showAlunos"
        @fechaModal="showAlunos = false"
    />
    <MyTable
        :data="state.treinadores"
        :columns="columns"
        :hideHeader="false"
        :itemsPerPage="12">
      <template v-slot:row="{ row }">
        <td class="text-sm pointer">
          <div>
            <h5>
              {{row.name}}
            </h5>
          </div>
          <div class="row small">
            <div class="col-12">
              <span class="text-danger">Cadastrado em:</span>
              {{$filters.datePtbr(row.created_at)}}
            </div>
            <div class="col-12 col-md-4">
              <span class="text-danger">CPF:</span> {{row.cpf}}
            </div>
            <div class="col-12 col-md-4">
              <span class="text-danger">Data Nasc.:</span> {{$filters.datePtbr(row.birth_date)}}
            </div>
            <div class="col-12 col-md-4">
              <span class="text-danger">Cidade/UF:</span> {{row.city}} / {{row.state}}
            </div>
            <div class="col-12 col-md-4">
              <span class="text-danger">Telefone:</span> {{row.phone}}
            </div>
            <div class="col-12 col-md-4">
              <span class="text-danger">E-mail:</span> {{row.email}}
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 text-center">
              <span class="btn btn-sm btn-success small" @click="listarAlunos(row.id)">
                Listar alunos
              </span>
            </div>
          </div>
        </td>
        <td class="text-sm align-content-center">
          <div class="float-end">
            <div>
                <router-link
                    class="w-100 pointer btn btn-outline-info btn-sm d-flex"
                    title="Detalhes"
                    :to="{ name: 'TreinadorDetalhe', params: { id: row.id } }"
                >
                  <i class="material-icons  me-1" >info</i>
                  Detalhes

                </router-link>
            </div>
<!--            <div class="mt-2">
              <span
                  class="pointer btn btn-outline-danger btn-sm d-flex"
                  title="Deletar "
              >
                <i class="material-icons me-1">delete</i>
                Deletar
              </span>
            </div>-->
          </div>
        </td>
      </template>
    </MyTable>
  </div>
</template>

<style scoped>

</style>