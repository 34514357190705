<script>
import MyTable from '../../components/MyTable.vue';
import { reactive, onMounted, ref, inject, watch } from 'vue'
import mensagensRepository from "@/services/api/mensagensRepository";
export default {
  components: {
    MyTable
  },
  setup () {
    const swal = inject("$swal");
    onMounted(() => {

      buscarMensagens()
    })

    const state = reactive({
      mensagens: [],
      mensagemId: 0
    })

    const columns = ref([
      {
        label: 'Tipo',
        field: 'message_type'
      },
      {
        label: 'Título',
        field: 'message_title'
      },
      {
        label: 'Ações',
        field: 'actions'
      }
    ])

    function buscarMensagens () {
      mensagensRepository.MensagensGet().then(response => {
        if(response.data.success) {
          state.mensagens = response.data.data
        }
      }).catch(error => {
        console.error(error)
      })
    }

    function deletar(item) {
      swal({
        text: `Deseja deletar a mensagem de ${item.message_type}`,
        showCancelButton: true,
        confirmButtonText: "Sim, quero deletar!",
        cancelButtonText: "Não, cancelar!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          deletarConfirma(item.id)
        }
      })
    }

    function deletarConfirma(id) {
      mensagensRepository.MensagensDelete(id).then(response => {

        if(response.data.success) {
          swal({
            title: "Removido!",
            text: "Mensagem removido com sucesso!",
            icon: "success",
            customClass: {
              confirmButton: "btn bg-gradient-success",
            },
            buttonsStyling: false,
          }).then(() => {
            buscarMensagens()
          })
        }
      }).catch(error => {
        console.error(error.message)
        swal({
          icon: 'error',
          title: 'Erro ao deletar!',
          text: error.message
        })
      })
    }

    return {
      state,
      columns,
      deletar
    }
  }
}
</script>

<template>
  <div>

    <div class="d-flex justify-content-between">
      <h3 class="mb-3">
        Lista de mensagens
      </h3>
      <router-link to="/mensagens-detalhe" class="text-decoration-none">
        <span
            class="h-auto pointer btn btn-info text-white btn-sm d-flex"
            title="Deletar "
        >
        <i class="material-icons me-1">add</i>
        Novo
      </span>
      </router-link>
    </div>
    <MyTable
        :data="state.mensagens"
        :columns="columns"
        :hideHeader="true"
        :itemsPerPage="12">
      <template v-slot:row="{ row }">
        <td class="text-sm pointer">
          {{ row.message_type }}
        </td>
        <td class="text-sm text-center pointer">
          {{ row.message_title }}
        </td>
        <td class="text-sm align-content-center">
          <div class="float-end">
            <div>
              <router-link
                  class="w-100 pointer btn btn-outline-info btn-sm d-flex"
                  title="Detalhes"
                  :to="{ name: 'MensagemDetalhe', params: { id: row.id } }"
              >
                <i class="material-icons  me-1" >info</i>
                Detalhes

              </router-link>
            </div>
            <div class="mt-2">
              <span
                  @click="deletar(row)"
                  class="pointer btn btn-outline-danger btn-sm d-flex"
                  title="Deletar "
              >
                <i class="material-icons me-1">delete</i>
                Deletar
              </span>
            </div>
          </div>
        </td>
      </template>
    </MyTable>
  </div>
</template>

<style scoped>

</style>