<script>
import coachesRepository from "@/services/api/coachesRepository";
import { reactive, onMounted, ref, inject, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default {
  setup() {
    const router = useRouter()
    const route = useRoute()

    onMounted(() => {
      if(route.params.id && parseInt(route.params.id) > 0) {
        state.treinadorId = parseInt(route.params.id)
        console.log(state.treinadorId)

        buscarDados()
      }
    })

    const state = reactive({
      treinadorId: 0,
      treinador: null
    })

    function buscarDados() {
      coachesRepository.TreinadorGetId(state.treinadorId).then(response => {
        if(response.data.success) {
          state.treinador = response.data.data
        }
      }).catch(error => {
        console.error(error)
      })
    }
    function salvar() {
      let treinador = {
        free_access: state.treinador['free_access']
      }
      coachesRepository.treinadorPut(state.treinadorId, treinador).then(response => {
        console.log(response.data)
        if(response.data.success) {
          router.push('/treinadores');
        }

      }).catch(error => {
        console.error(error)
      })
    }

    function voltar() {
      router.back()
    }

    return {
      state,
      salvar,
      voltar
    }
  }
}

</script>

<template>
  <div class="card">
    <div class="card-body" v-if="state.treinador != null">
      <div>
        <h5>
          {{state.treinador.name}}
        </h5>
      </div>
      <div class="row small">
        <div class="col-12">
          <span class="text-danger">Cadastrado em:</span>
          {{$filters.datePtbr(state.treinador.created_at)}}
        </div>
        <div class="col-12 col-md-4">
          <span class="text-danger">CPF:</span> {{state.treinador.cpf}}
        </div>
        <div class="col-12 col-md-4">
          <span class="text-danger">Data Nasc.:</span> {{$filters.datePtbr(state.treinador.birth_date)}}
        </div>
        <div class="col-12 col-md-4">
          <span class="text-danger">Cidade/UF:</span> {{state.treinador.city}} / {{state.treinador.state}}
        </div>
        <div class="col-12 col-md-4">
          <span class="text-danger">Telefone:</span> {{state.treinador.phone}}
        </div>
        <div class="col-12 col-md-4">
          <span class="text-danger">E-mail:</span> {{state.treinador.email}}
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-4">
          <div class="form-group">
            <label class="form-label mt-2">Acesso gratuito</label>
            <select class="form-control form-control-sm custom-select" v-model="state.treinador.free_access">
              <option value="1" >SIM</option>
              <option value="0" >NÃO</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-end">
          <button type="button" class="btn btn-sm btn-warning me-2" @click.prevent="voltar">Voltar</button>
          <button type="button" class="btn btn-sm btn-success" @click.prevent="salvar">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>