<template>
  <transition name="fade">
    <div class="modal-mask" v-if="state.showModal"  >
      <div class="modal fade " :class="{ show: state.showModal, 'd-block': state.showModal }" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" >Lista de alunos</h5>
              <button type="button" class="btn-close text-dark" @click="fechaModal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <MyTable
                      :data="state.alunos"
                      :columns="columns"
                      :hideHeaderTable="true"
                      :itemsPerPage="12">
                    <template v-slot:row="{ row }">
                      <td class="text-sm pointer">
                        <div>
                          <h5>
                            {{row.name}}
                          </h5>
                        </div>
                        <div class="row small">
                          <div class="col-12">
                            <span class="text-danger">Cadastrado em:</span>
                            {{$filters.datePtbr(row.created_at)}}
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="text-danger">CPF:</span> {{row.cpf}}
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="text-danger">Data Nasc.:</span> {{$filters.datePtbr(row.birth_date)}}
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="text-danger">Cidade/UF:</span> {{row.city}} / {{row.state}}
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="text-danger">Telefone:</span> {{row.phone}}
                          </div>
                          <div class="col-12 col-md-4">
                            <span class="text-danger">E-mail:</span> {{row.email}}
                          </div>
                        </div>
                      </td>
                    </template>
                  </MyTable>
                </div>
              </div>
            </div>
<!--            <div class="modal-footer">
              <button type="button" class="btn bg-gradient-primary" @click.prevent="salvar">Salvar</button>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {reactive, onMounted, ref, inject, watch} from 'vue'
import MyTable from '../../components/MyTable.vue';
import alunosRepository from "@/services/api/alunosRepository";
export default {
  name: "alunos-modal",
  components: {
    MyTable
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    treinadorId: {
      type: Number
    }
  },
  setup(props, {emit}) {
    //const swal = inject('$swal')
    // const emit = defineEmits(['closeModal'])
    //const store = useStore()
    onMounted(() => {
      console.log('sdsdsds')
      state.showModal = props.show
      state.treinadorId = props.treinadorId
      if(state.treinadorId > 0) {
        buscarAlunos()
      }

    })

    watch(() => props.show, (newValue, oldValue) => {
      console.log('watch show')
      state.showModal = newValue
    });

    watch(() => props.treinadorId, (newValue, oldValue) => {
      console.log('watch')
      state.treinadorId = newValue
      console.log(state.treinadorId)
      buscarAlunos()
    });

    // let modalidadeSelecionadaId = ref('0')

    const state = reactive({
      alunos: '',
      showModal: false,
      treinadorId: 0
    })

    const columns = ref([
      {
        label: '',
        field: 'name'
      }
    ])

    function buscarAlunos() {
      alunosRepository.AlunosGet(state.treinadorId).then(response => {
        if(response.data.success) {
          state.alunos = response.data.data
        }
      }).catch(error => {
        console.error(error)
      })
    }

    const fechaModal = () => {
      emit('fechaModal')
    }
    return {
      state,
      columns,
      fechaModal
    }
  }
};
</script>
