import { createWebHistory, createRouter } from 'vue-router'

import Login from './views/Login.vue'
import TemplateDefault from './views/template/Template.vue'
import Dashboard from './views/dashboad/dashboard.vue'
import { useStore } from './store';
import TreinadoresLista from "@/views/treinadores/TreinadoresLista.vue";
import TreinadorDetalhes from "@/views/treinadores/TreinadorDetalhes.vue";
import MensagensLista from "@/views/mensagens/MensagensLista.vue";
import MensagemForm from "@/views/mensagens/MensagemForm.vue";


const routes = [
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/",
        name: "Home",
        component: TemplateDefault,
        redirect: "/dashboard",
        meta: { requiresLogin: true },
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                component: Dashboard,
            },
            {
                path: "/treinadores",
                name: "Treinadores",
                component: TreinadoresLista,
            },
            {
                path: "/treinadores/:id",
                name: "TreinadorDetalhe",
                component: TreinadorDetalhes,
            },
            {
                path: "/mensagens",
                name: "Mensagens",
                component: MensagensLista
            },
            {
                path: "/mensagens-detalhe/:id?",
                name: "MensagemDetalhe",
                component: MensagemForm
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    const authStore = useStore();
    console.log(authStore.isAuthenticated)
    //se precisar de login e nao estiver autenticado, manda para tela de login
    if (to.meta.requiresLogin && !authStore.isAuthenticated) {
        next('/login')
    } else if (authStore.isAuthenticated && to.path === '/login') {
        //SE ESTIVER AUTENTICADO E TENTAR ACESSAR LOGIN, DIRECIONA PARA DASHBOARD
        next('/dashboard')
    } else {
        next()
    }
    /*if (to.meta.requiresLogin && !authStore.isAuthenticated) {
        next('/login');
    } else {
        next();
    }*/
});

export default router;