<script>

import {defineComponent, reactive} from "vue";
import Sidebar from "./Sidebar.vue";
import SidebarMobile from "@/views/template/SidebarMobile.vue";

export default {
  components: {
    SidebarMobile,
    Sidebar
  },
  setup () {
    const state = reactive({
      isOpen: false
    })

    function toggleSidebar() {
      state.isOpen = !state.isOpen
    }

    return {
      toggleSidebar,
      state
    }
  }
}
</script>

<template>
  <div class="d-flex">
    <Sidebar
        class="d-none d-md-block"
        :showMenu="state.isOpen"
    />
    <SidebarMobile
        :showMenu="state.isOpen"
    />

    <main class="main-content border-radius-lg w-100">
      <!-- Navbar -->
          <nav class=" d-md-none navbar navbar-main navbar-expand-lg shadow-none border-radius-xl" data-scroll="true">
            <div class="bg-light container-fluid py-1 px-3">
              <button
                  class="btn btn-dark m-1 btn-sm d-flex"
                  @click="toggleSidebar">
                <i class="material-icons">menu</i>
                Menu
              </button>
            </div>
          </nav>

      <!-- End Navbar -->
      <div class="container-fluid  py-md-4" style="min-height: 100vh;">
        <router-view />
      </div>
    </main>
  </div>












</template>

<style>
  .pointer {
    cursor: pointer;
  }
  .dt-bg-primary {
    background-color: #3b3b72 ;
  }
  .dt-bg-primary-dark {
    background-color: #111155 ;
  }
  .dt-color-primary {
    color: #3b3b72;
  }
  .dt-bg-secondary {
    background-color: #f58634;
  }
  .dt-color-secondary, .active-menu-item {
    color: #f58634;
  }
</style>