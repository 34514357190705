<script >
import { reactive, onMounted, ref, inject } from "vue";
import indicadorTreinador from './indicadorTreinador.vue'
import indicadorAluno from './indicadorAluno.vue'
import indicadorTreinos from './indicadorTreinos.vue'
import { useStore } from './../../store';
import dashboardRepository from "@/services/api/dashboardRepository";
export default {
  components: {
    indicadorTreinador,
    indicadorAluno,
    indicadorTreinos,
  },
  setup() {
    const authStore = useStore();

    const state = reactive({
      indicadores: {
        totalCoaches: {
          total: 0,
          M: 0,
          F: 0
        },
        totalAlunos: {
          total: 0,
          M: 0,
          F: 0
        }
      }
    });

    onMounted(() => {
      console.log('authStore.isAuthenticated')
      console.log(authStore.isAuthenticated)
      buscaIndicadores()
    })

    function buscaIndicadores() {
      dashboardRepository.Indicadores().then(response => {
        if(response.data.success) {
          state.indicadores = response.data.data
          console.log('state.indicadores')
          console.log(state.indicadores)
        }
      }).catch(error => {
        console.error(error)
      })
    }

    return {
      state
    }
  }
}

</script>

<template>
  <div class="row">
    <div class="col-12 col-xl-4 col-lg-6 col-md-6">
      <indicador-treinador
          :indicador="state.indicadores['totalCoaches']"
      />
    </div>
    <div class="col-12 col-xl-4 col-lg-6 col-md-6">
      <indicador-aluno
          :indicador="state.indicadores['totalAlunos']"
      />
    </div>
    <div class="col-12 col-lg-6 col-md-6 col-xl-4 mt-md-3 mt-xl-0">
      <indicador-treinos
          :total="state.indicadores['totalTreinos']"
      />
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-12 col-md-6">
      <div class="card border">
        <div class="card-body">
          <div class="border-bottom font-weight-bold pb-2">
            Os 10 treinadores mais recentes
          </div>
          <div class="my-2" v-for="(item, index) in state.indicadores['listaCoaches']" :key="index">
            <div class="d-flex border-bottom">
              <div class="align-content-around me-3">
                <i class="material-icons">person</i>
              </div>
              <div>
                <p class="font-weight-bold m-0">{{ item['name'] }}</p>
                <p class="m-0 text-sm">Cidade / Estado</p>
              </div>
              <div class="font-weight-bold text-end ms-auto">
                Ativo
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="card border">
        <div class="card-body">
          <div class="border-bottom font-weight-bold pb-2">
            Os 10 alunos mais recentes
          </div>
          <div class="my-2" v-for="(item, index) in state.indicadores['listaAlunos']" :key="index">
            <div class="d-flex border-bottom">
              <div class="align-content-around me-3">
                <i class="material-icons">person</i>
              </div>
              <div>
                <p class="font-weight-bold m-0">{{ item['name'] }}</p>
                <p class="m-0 text-sm">{{ item['city'] }} / {{ item['state'] }}</p>
              </div>
              <div class="font-weight-bold text-end ms-auto">
                Ativo
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>