import Repository from './repository'

export default {
    TreinadoresGet () {
        return Repository.get('coaches')
    },
    TreinadorGetId (id) {
        return Repository.get(`coaches/${id}`)
    },
    treinadorPut (id, item) {
        return Repository.put(`coaches/${id}`, item)
    }
}

