<script>
import { useStore } from '@/store';
import { reactive, onMounted, ref, inject, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import loginRepository from "./../services/api/loginRepository";
export default {

  setup() {
    const store = useStore();
    const router = useRouter()
    const route = useRoute()

    const state = reactive({
      user: '',
      pass: ''
    });

    function login() {
      const formDataitems = new FormData()
      formDataitems.append('email', state.user)
      formDataitems.append('password', state.pass)
      loginRepository.Authenticate(formDataitems).then(response => {
        console.log(response.data)
        if(response.success) {
          store.login(response.data.token)
          console.log('sdsdsdsd')
          router.push('/dashboard')
        }

      }).catch(error => {
        console.error(error)
      })

    }
    return {
      state,
      login
    }
  }
}

</script>

<template>
  <!-- Section: Design Block -->
  <section class="background-radial-gradient overflow-hidden page-header" style="height: 100vh !important;">


    <div class="container text-center text-lg-start my-5">
      <div class="row gx-lg-5 align-items-center mb-5">
        <div class="col-lg-6 mb-5 mb-lg-0" style="z-index: 10">
          <img src="./../assets/images/logo/logo-full.png" alt="" class="img-fluid" >
        </div>

        <div class="col-lg-6 mb-5 mb-lg-0 position-relative">


          <div class="card bg-glass">
            <div class="card-body px-4 py-5 px-md-5">
              <h2 class="text-center">
                Área do gestor
              </h2>
              <div>


                <!-- Email input -->
                <div class="form-outline mb-4">
                  <label class="form-label" for="form3Example3">Email</label>
                  <input type="email" v-model="state.user" id="form3Example3" class="form-control" />

                </div>

                <!-- Password input -->
                <div class="form-outline mb-4">
                  <label class="form-label" for="form3Example4">Senha</label>
                  <input type="password" v-model="state.pass" id="form3Example4" class="form-control" />

                </div>



                <!-- Submit button -->
                <button type="button" data-mdb-button-init data-mdb-ripple-init class="btn btn-behance btn-block mb-4" @click.prevent="login">
                  Acessar
                </button>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Section: Design Block -->


</template>

<style scoped>
.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
  hsl(218, 41%, 35%) 15%,
  hsl(218, 41%, 30%) 35%,
  hsl(218, 41%, 20%) 75%,
  hsl(218, 41%, 19%) 80%,
  transparent 100%),
  radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}



.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}

.page-header {
  padding: 0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: 50%;
}
</style>