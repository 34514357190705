import axios from 'axios'
import { useStore } from '@/store'
import { getActivePinia } from 'pinia'

// const baseURL = 'https://apidiariodetreino2.agenc.briaportes.com.br/api/gestor/'
const baseURL = 'http://diariodetreino.agilesaas.com.br/api/gestor/'

let axiosApi = axios.create(
  {
    baseURL,
  },
  5000,
)
axiosApi.interceptors.request.use(
  (config) => {
    const pinia = getActivePinia()
    if (pinia) {
      const store = useStore(pinia)
      let token = store.isAuthenticated
      //config.headers['Cache-Control'] = 'no-cache'
      if (token) {
        config.headers['Authorization'] = `Bearer ${store.token}`
      }
    } else {
      console.error('Pinia não está ativo')
    }

    return config
  },

  (error) => {
    //store.commit('SET_SPLASH_SCREEN', false)
    return Promise.reject(error)
  },
)

export default axiosApi
