<script>
import { useStore } from '@/store';
import { reactive, onMounted, ref, inject, watch } from "vue";
import { useRouter } from "vue-router";
export default {
  props: {
    showMenu: {
      type: Boolean
    }
  },
  setup(props) {
    onMounted(() => {
      state.isOpen = props.showMenu
    })

    watch(() => props.showMenu, (newValue, oldValue) => {
      console.log('watch')
      state.isOpen = newValue
    });

    const store = useStore();
    const router = useRouter();

    const state = reactive({
      isOpen: false,
      links: store.links
    });

    function toggleSidebar() {
      state.isOpen = !state.isOpen;
    }
    function logout() {
      store.logout()
      router.push('/login')
    }
    return {
      logout,
      toggleSidebar,
      state
    }
  }
}
</script>

<template>
  <div class="bg-dark text-white d-md-none" :class="['offcanvas offcanvas-start', { 'show': state.isOpen }]" tabindex="-1">
    <div class="offcanvas-header">
      <div class="sidenav-header">
        <i class="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i>
        <a class="navbar-brand text-center m-0" href="#" target="_blank">
          <span class="ms-1 font-weight-bold text-white">Gestor <br>Diário de treino</span>
        </a>
      </div>
      <button
          type="button"
          class="btn-close text-white text-reset"
          aria-label="Close"
          @click="toggleSidebar">
        <i class="material-icons opacity-10">menu</i>
      </button>
    </div>
    <div class="offcanvas-body text-white">
      <hr class="horizontal light mt-0 mb-2">

      <ul class="navbar-nav">
        <li class="nav-item" v-for="(item, index) in state.links" :key="index">
          <a class="nav-link text-white d-flex" :href="item.link">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">{{ item.icone }}</i>
            </div>
            <span class="nav-link-text ms-1">{{ item.nome }}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-white d-flex" href="#" @click="logout">
            <div class="text-white text-center me-2 d-flex align-items-center justify-content-center">
              <i class="material-icons opacity-10">logout</i>
            </div>
            <span class="nav-link-text ms-1">Sair</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.offcanvas {
  width: 250px;
}
.offcanvas.show {
  transform: translateX(0);
}
</style>